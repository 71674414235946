/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from 'react';
import { isAndroid, isIOS } from 'react-device-detect';
import './App.css';
import appStoreBadge from './assets/app-store-badge.svg';
import logo from './assets/logo.svg';

const App: React.FC = () => {
  useEffect(() => {
    if (isAndroid) {
      const url =
        'https://play.google.com/store/apps/details?id=com.remondis.maex';
      window.location.replace(url);
    } else if (isIOS) {
      const url = 'https://apps.apple.com/app/maex/id6444179680';
      window.location.replace(url);
    }
  }, []);

  return (
    <div className="container">
      <div className="background" />
      <header className="header">
        <img src={logo} className="logo" alt="logo" />
        <a
          className="badge"
          href="https://play.google.com/store/apps/details?id=com.remondis.maex&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1"
        >
          <img
            alt="Jetzt bei Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/de_badge_web_generic.png"
          />
        </a>
        <a
          className="badge"
          href="https://apps.apple.com/app/maex/id6444179680"
        >
          <img alt="Jetzt im App Store" src={appStoreBadge} />
        </a>
      </header>
    </div>
  );
};

export default App;
